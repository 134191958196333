import IconButton from '../Common/IconButton/button';
import './style.scss';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-wrapper">
                    <div className="footer-contact">
                        <div className="contact-item"><IconButton iconUrl={'/icons/phone.svg'} width={25} height={25} />+38 (096) 547 7575</div>                        
                    </div>
                    <div className="footer-contact">
                        <div className="contact-item"><IconButton iconUrl={'/icons/time.svg'} width={25} height={25} />
                        Пн. - Пт. 8:00 - 21:00</div>
                        <div className="contact-item"><IconButton iconUrl={'/icons/place.svg'} width={25} height={25} />Житомирська обл., м. Коростишів, Соборна Площа 3</div>
                    </div>
                </div>

            </footer>
        </>
    )
}

export default Footer;