import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const SaleProductCart = ({ id, urlImg, price, code, stone, addProductToCart }) => {
    const [imageCount, setImageCount] = useState(0)

    const AddToCart = (e) => {
        e.preventDefault();
        addProductToCart({ id, urlImg, price, code, stone, count: 1 })
    }

    return (
        <>
            <Link to={`../catalog/${id}`}>
                <div className="sale-cart">
                    <div className="sale-image">
                        <img src={typeof (urlImg) == typeof ([]) ? urlImg[imageCount] : urlImg} />
                    </div>
                    <div className="sale-info">
                        <div className="sale-desc">
                            <div className="row">
                                <div className="row-value">{code}</div>
                            </div>
                        </div>
                        <div className="sale-price">{price} <br />грн/комплект</div>
                    </div>
                </div>
            </Link>

        </>
    )
}

export default SaleProductCart;