import { Link } from 'react-router-dom';
import './style.scss';
import { useState } from 'react';
import { Addittion, Military, Optom, TermoSocks, WarmSocks, WarmSocksSubCategory } from '../../../logic/const/constants';
import IconButton from '../IconButton/button';

const Catalog = ({ isOpen, onClose }) => {

    const [selectedCategory, setSelectedCategory] = useState(WarmSocks)

    const setCategory = (category) => {
        setSelectedCategory(category)
    }

    return (
        <>
            <div className={`catalog-background ${!isOpen ? 'close' : ''}`} onClick={onClose}>
            </div>
            <div className={`catalog-pc ${!isOpen ? 'close' : ''}`}>
                <div className="list" gridArea={'A'}>
                    <div className="title"><Link to={`catalog?category=${WarmSocks}`} onClick={onClose}>Шкарпетки</Link></div>
                    <ul>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${WarmSocksSubCategory}`} onClick={onClose}>Теплі шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${TermoSocks}`} onClick={onClose}>Термо шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Military}`} onClick={onClose}>Військові шкарпетки</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'B'}>
                    <div className="title">Інформація</div>
                    <ul>
                        <li><Link to={'contacts'}>Контакти</Link></li>
                        <li><Link to={'delivery'}>Доставка і оплата</Link></li>
                    </ul>
                </div>
            </div>
            <div className={`catalog-mobile ${!isOpen ? 'close' : ''}`}>
                <div className="menu-info">
                    <Link to={"/"}>
                        <div className="logo">
                            KRST SOCKS
                        </div>
                    </Link>

                    <IconButton width={'24px'} height={'24px'} iconUrl={'/icons/viber-icon.svg'} />
                    <IconButton width={'24px'} height={'24px'} iconUrl={'/icons/telegram-icon.svg'} />
                    <div className="phone-number">
                        <a href='tel:+38 (096) 547 7575'>+38 (096) 547 7575</a>
                    </div>
                </div>

                <div className="menu-catalog">
                    <div className="title-column">
                        <div className="title" onClick={() => setCategory(WarmSocks)}>Шкарпетки</div>
                    </div>
                    <ul className='category-list'>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${WarmSocksSubCategory}`} onClick={onClose}>Теплі шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${TermoSocks}`} onClick={onClose}>Термо шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Military}`} onClick={onClose}>Військові шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Optom}`}>Оптом</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Addittion}`}>Додаткові товари</Link></li>
                        <li><Link to={'contacts'}>Контакти</Link></li>
                        <li><Link to={'delivery'}>Доставка і оплата</Link></li>
                    </ul>
                </div>

                {/* <div className="list" style={{ display: 'none' }}>
                    <ul>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Addition}`} onClick={onClose}>Контакти</Link></li>
                    </ul>
                </div> */}
            </div>
        </>

    )
}

export default Catalog;