import React, { useEffect } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';
import { Link } from 'react-router-dom';

const ThanksPage = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });        
    }, [])

    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="thanks-container">
                    <h2>Дякую за замовлення!</h2>
                    <p className='details'>Заявка на замовлення відправлена на опрацювання. Найближчим часом з вами зв'яжиться менеджер з продажу для підтвердження замовлення</p>
                    <Link to='/catalog?category=WarmSocks&subCategory=warm-socks'><button className='revert-button primary-button'>До каталогу</button></Link>
                </div>
            </CommonLayout>
        </>
    )
}

export default ThanksPage;