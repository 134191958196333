import React from 'react';
import './style.scss';
import { WarmSocksForFilterBlock, WarmSocks } from '../../../../logic/const/constants';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../../logic/hooks/useQuery';

const FilterBlock = ({ visible, setVisible, onChangeMinPrice, onChangeMaxPrice }) => {
    const query = useQuery();
    const category = query.get('category')
    let subCategory = [];

    switch (category) {
        case (WarmSocks):
            subCategory = WarmSocksForFilterBlock;
            break;
        default: subCategory = [];
    }

    const renderItems = () => {
        return subCategory.map(x => {
            return <li><Link to={`?category=${category}&subCategory=${x[0]}`}>{x[1]}</Link></li>
        });
    }



    return (
        <>
            <div className={`filter-block ${visible == true ? 'show' : ''}`}>
                <div className="close-button" onClick={() => setVisible(false)}>X</div>
                <div className="filter">
                    <div className="title">
                        КАТЕГОРІЯ
                    </div>
                    <ul>
                        <li><Link to={`?category=${WarmSocks}`}>Шкарпетки</Link></li>
                    </ul>
                </div>
                <div className="filter">
                    <div className="title">
                        ТИП ТОВАРА
                    </div>
                    <ul>
                        {renderItems()}
                    </ul>
                </div>
                <div className="filter">
                    <div className="title">
                        ЦІНА
                    </div>
                    <div className="price-input-group">
                        <input type="number" placeholder='Від' onChange={e => onChangeMinPrice(e.target.value)}></input>
                        <input type="number" placeholder='До' onChange={e => onChangeMaxPrice(e.target.value)}></input>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterBlock;