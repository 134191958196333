import { Addittion, WarmSocks, WarmSocksSubCategory } from "../logic/const/constants";

export const CatalogData = [
    {
        id: "2",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["36-40", "41-45"],
        urlImg: ["/images/socks6_1.jpg", "/images/socks6_2.jpg", "/images/socks6_3.jpg"],
        code: 'Чоловічі термошкарпетки Columbia 6 пар',
        price: 300,
        desc: `
- Чоловічі термошкарпетки Columbia в чорному кольорі є ідеальним рішенням для комфортного носіння в холодні дні.
- Виготовлені з високоякісної тканини, включаючи шерсть, акрил, спандекс та інші матеріали.
- Ці шкарпетки забезпечують максимальний захист від холоду та високий комфорт завдяки гарній еластичності та вдосконаленому фасону.
- Вони чудово видаляють вологу і підходять для температурного режиму від +5°C до -25°C, що робить їх чудовим вибором для активного відпочинку на природі, вечірніх прогулянок та тривалих перебування на холоді у прохолодні осінні та зимові дні.`,
        char: `
Виробник Columbia
Вид виробу Шкарпетки
Стать Чоловіча
Розмір шкарпеток 36-40; 41-44
Колір  Чорний, Синій, Сірий`
    }, {
        id: "1",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks1_1.jpg", "/images/socks1_2.jpg", "/images/socks1_3.jpg", "/images/socks1_4.jpg", "/images/socks1_5.jpg"],
        code: 'Чоловічі Махрові шкарпетки "КОМФОРТ" бавовна (ME403/7) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Комфорт" хорошої якості.`,
        char: `
    Колір - На фото (Можливо зміни кольорів)
    Розмір - Універсальний (тягнуться від 40 до 45 розміру)
    Кількість виробів в упаковці - 12 пар (одного розміру і кольору)
    склад - 90% Бавовна, 10% Лайкра
    Виробник - Україна`
    },

    {
        id: "3",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks3_1.jpg", "/images/socks3_2.jpg", "/images/socks3_3.jpg", "/images/socks3_4.jpg"],
        code: 'Шкарпетки чоловічі Milano бавовна МАХРА (Арт. ME4039)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Milano" хорошої якості.`,
        char: `
        Сезон - Зима
        Колір ― Чорний
        Розмір ― Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру та кольору)
        Склад ― 100% Cotton
        Виробництво ― Україна`
    },
    {
        id: "4",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks4_1.jpg", "/images/socks4_2.jpg", "/images/socks4_3.jpg"],
        code: 'Чоловічі Махрові шкарпетки "КОМФОРТ" бавовна (ME403/6) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Комфорт", гарна якість, різні забарвлення.`,
        char: `
        Колір - На фото
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 90% Бавовна, 10% Еластан
        Виробник - Україна`
    },
    {
        id: "5",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks5_1.jpg", "/images/socks5_2.jpg", "/images/socks5_3.jpg"],
        code: 'Чоловічі Махрові шкарпетки "КОМФОРТ" бавовна (ME403/6) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Комфорт", гарна якість, різні забарвлення.`,
        char: `
        Колір - На фото
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 90% Бавовна, 10% Еластан
        Виробник - Україна`
    },
    {
        id: "7",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks7_1.jpg", "/images/socks7_2.jpg", "/images/socks7_3.jpg"],
        code: 'Шкарпетки чоловічі "МОНТЕКС" Бамбук МАХРА (Арт. ME403/11) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Монтекс", гарна якість, однотонні.`,
        char: `
        Колір - Чорний
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру і кольору)
        Склад - 90% Бавовна, 10% Лайкра
        Виробництво - Україна`
    },
    {
        id: "8",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks8_1.jpg", "/images/socks8_2.jpg", "/images/socks8_3.jpg"],
        code: 'Шкарпетки чоловічі "Житомир" бавовна МАХРА (Арт. ME403/13) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, різні забарвлення.`,
        char: `
        Сезон - Зима
        Колір - На фото
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 90% Бавовна, 10% Лайкра
        Виробництво - Україна`
    },
    {
        id: "9",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks9_1.jpg", "/images/socks9_2.jpg", "/images/socks9_3.jpg", "/images/socks9_4.jpg", "/images/socks9_5.jpg"],
        code: 'Шкарпетки чоловічі "MILANO" МАХРА БАТЬКОВ (Арт. ME403/8) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Milano", гарна якість, різні забарвлення.`,
        char: `
        Колір ― На фото
        Розмір - Універсальний (тягнуться від 40 до 45 розмірів)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бавовна, 10% Лайкра
        Виробник - Україна`
    },
    {
        id: "10",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks10_1.jpg", "/images/socks10_2.jpg", "/images/socks10_3.jpg"],
        code: 'Шкарпетки чоловічі "Житомир" бавовна МАХРА (Арт. ME401B) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, однотонні.`,
        char: `
        Сезон - Зима
        Колір - Чорний
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру і кольору)
        Склад - 90% Бавовна, 10% Лайкра
        Виробництво - Україна`
    },
    {
        id: "11",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks11_1.jpg", "/images/socks11_2.jpg", "/images/socks11_3.jpg"],
        code: 'Шкарпетки чоловічі Житомир бавовна МАХРА (Арт. ME401)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, однотонні, різні забарвлення.`,
        char: `
        Сезон - Зима
        Колір - На фото
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 90% Бавовна, 10% Лайкра
        Виробництво - Україна`
    },
    {
        id: "12",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks12_1.jpg", "/images/socks12_2.jpg", "/images/socks12_3.jpg"],
        code: 'Шкарпетки чоловічі Житомир бавовна МАХРА (Арт. ME40312)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир" хорошої якості.`,
        char: `
        Сезон - Зима
        Колір - Чорний
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру і кольору)
        Склад - 85% Cotton, 15% Lycra
        Виробництво - Україна`
    },
    {
        id: "13",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks13_1.jpg", "/images/socks13_2.jpg", "/images/socks13_3.jpg"],
        code: 'Шкарпетки чоловічі "Житомир" бавовна МАХРА (Арт. ME403) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, однотонні, різні забарвлення.`,
        char: `
        Сезон - Зима
        Колір - На фото
        Розмір - Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 90% Бавовна, 10% Лайкра
        Виробництво - Україна`
    },
    {
        id: "14",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks14_1.jpg", "/images/socks14_2.jpg", "/images/socks14_3.jpg", "/images/socks14_4.jpg", "/images/socks14_5.jpg"],
        code: 'Шкарпетки чоловічі "Житомир" бавовна МАХРА (Арт. ME403B) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир" хорошої якості.`,
        char: `
    Сезон - Зима
    Колір - Чорний
    Розмір - Універсальний (тягнуться від 40 до 45 розміру)
    Кількість виробів в упаковці - 12 пар (одного розміру і кольору)
    Склад - 90% Бавовна, 10% Лайкра
    Виробництво - Україна`
    },
    {
        id: "16",
        category: WarmSocks,
        subCategory: Addittion,
        sizes: '',
        urlImg: ["/images/addition1_1.jpg", "/images/addition1_2.jpg", "/images/addition1_3.jpg"],
        code: 'Термостілки самонагрівані хімічні одноразові для ніг FOOT Warmers',
        price: 300,
        desc: `Самогріюча грілка має форму устілки, тому ідеально підходить для обігріву стоп. Екологічно чисті, безпечні термоустілки забезпечують теплом ваші ноги в подорожах чи прогулянках, в умовах бойових дій в холодну пору року, в приміщенні чи на вулиці. Середня температура нагрівання +41 °С. Для активації хімічної реакції потрібно відкрити упаковку і кілька разів зтрусити її складові, контактуючи з кислородом, починати виділяти тепло. Покласти грілку біля взуття. Грілка розрахована не так на нагрівання повітря навколо себе, а частини тіла, з якою контактує (стопою). Для правильної роботи грілки після початку реакції слід покласти саме на закритий простір.`,
        char: `
        Склад: залізний порошок, активоване вугілля, сіль,
        Кількість: 2 устілки в упаковці,
        Довжина устілки: 25см,
        Розмір: 40-43 см,
        Температура нагріву: +41°С.
        Термін дій: до 10 годин,
        Вага: 92г
        Термін придатності до споживання: 4 роки`
    },
    {
        id: "15",
        category: WarmSocks,
        subCategory: Addittion,
        sizes: '',
        urlImg: ["/images/addition4_1.jpg", "/images/addition4_2.jpg", "/images/addition4_3.jpg"],
        code: 'Килимок із підігрівом 50×40 см із термоізоляцією Стандарт',
        price: 300,
        desc: `Килимок з підігрівом Теплик - це екологічний та безпечний електричний прилад для обігріву людей, тварин та предметів`,
        char: `Локальне обігрів економно використовує ресурси та не споживає зайву електроенергію. За допомогою інфрачервоної плівки тепло та комфорт стають безпечними та доступними. М'які інфрачервоні промені гріють так само, як сонячне світло, але не шкодять.

        Килимок швидко нагріває предмети, що знаходяться на ньому. Режим роботи – до 12 години та під наглядом.`
    },
    {
        id: "17",
        category: WarmSocks,
        subCategory: Addittion,
        sizes: '',
        urlImg: ["/images/addition2_1.jpg", "/images/addition2_2.jpg", "/images/addition2_3.jpg"],
        code: 'Грілки одноразові для рук TakeHOT',
        price: 300,
        desc: `Хімічні грілки для рук призначені для того, щоб мінімізувати втрату тепла та примножити його. Багато спортсменів та звичайних любителів активного зимового відпочинку віддають перевагу саме ручним одноразовим грілкам, які захищають пальці та шкіру в цілому від холодного повітря набагато краще, ніж просто рукавиці. Разом ці дві речі справляють приголомшливий ефект - тепло вашим рукам гарантовано! Інтернет-магазин Grelka представляє вашій увазі TakeHOT - якісна, дієва та безпечна хімічна грілка, яка точно врятує від морозу!

    Сьогодні грілка для рук хімічна неабияк актуальна в застосуванні військовими на фронті. Адже, перебуваючи тривалий час на холоді, просто неможливо обходитися без допоміжних міні-обігрівачів. Саме тут на допомогу прийдуть хімічні грілки для рук!
    
    ТейкХОТ - грілка для рук хімічна, яка легко активізується повітрям. Вона швидко нагрівається до максимальної температури, яка є оптимальною для людського тіла. Обігрівач безперервно виробляє тепло до 10 годин! Рекомендація: застосовуйте обігрівач рук в ізольованому середовищі для більшого розвитку тепла. Окрім більш звичного використання всередині рукавиць, грілку також можна вставляти у кишені одягу. 
    
    Хімічна грілка для рук має ряд переваг: вона компактна, а значить, її завжди можна брати із собою та використовувати за потреби; вона кліматично нейтральна, що обіціяє повну компенсацію вуглецевого сліду; вона безпечна для екології, адже грілку згодом варто утилізовувати як звичайне сміття. Рекомендація: не варто взаємодіяти з хімічною грілкою під час сну та використовувати її у медичних цілях.`,
        char: `
    Час нагріву: до 10 годин
    Розмір упаковки: 55 х 95 мм
    Вміст упаковки: 10 пар/20 шт
    Склад: порошок заліза, активоване вугілля, вода, кухонна сіль, вермикуліт
    Вага обігрівача: 30 г
    Маса упаковок: 1200 г
    Термін придатності до споживання: 11.2024
    Виробник: TakeHot`
    },
    {
        id: "18",
        category: WarmSocks,
        subCategory: Addittion,
        sizes: '',
        urlImg: ["/images/addition3_1.jpg", "/images/addition3_2.jpg", "/images/addition3_3.jpg"],
        code: 'Сушарка для взуття Ningbo Shoes Dryer 204274',
        price: 300,
        desc: `Сушарка для взуття Ningbo зсередини висушить взуття швидко і якісно,   не пересушуючи матеріал і не деформуючи взуття. Сушарка Ningbo універсальна, тому підходить для сушіння будь-якого взуття, незалежно від розміру та матеріалу взуття.`,
        char: `
    - Витрата електроенергії при цьому дуже незначна.
    - Повне висихання взуття триває від 2 до 5 годин.    
    - Не виймати сушарки із взуття за шнур.    
    - Заборонено перевіряти ступінь сухості взуття під час увімкненої сушарки.    
    - Електрична сушарка для взуття Ningbo швидко та ефективно усуває вологу, неприємний запах та запобігає появі грибка.    
    - Сушарка легко поміщається в сумочці, тому користуватися приладом для сушіння взуття можна не лише вдома, а й у відрядженні.`
    },
    {
        id: "19",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks19_1.jpg", "/images/socks19_2.jpg", "/images/socks19_3.jpg"],
        code: 'Шкарпетки чоловічі бавовна МАХРА (Арт. ME1702) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові термошкарпетки доброї якості, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 20% Бамбук, 75% Бавовна, 5% Лайкра
        Виробництво ― Україна`
    },
    {
        id: "22",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks22_1.jpg", "/images/socks22_2.jpg", "/images/socks22_3.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM412) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки доброї якості, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 41 до 46 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бамбук, 5% Спандекс, 5% Лайкра 
        Виробництво ― Україна`
    },
    {
        id: "23",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks23_1.jpg", "/images/socks23_2.jpg", "/images/socks23_3.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM524) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки доброї якості, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 41 до 46 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бамбук, 5% Спандекс, 5% Лайкра 
        Виробництво ― Україна`
    },
    {
        id: "24",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks24_1.jpg", "/images/socks24_2.jpg", "/images/socks24_3.jpg"],
        code: 'Шкарпетки чоловічі Sport МАХРА БАМБУК (Арт. OAM528)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки "Sport" з антибактеріальним ефектом, гарна якість, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бамбук, 5% Спандекс, 5% Лайкра 
        Виробництво ― Україна`
    },
    {
        id: "25",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks25_1.jpg", "/images/socks25_2.jpg", "/images/socks25_3.jpg"],
        code: 'Шкарпетки чоловічі заниженные "Sport" МАХРА БАМБУК (Арт. OAM527) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки "Sport", гарна якість, занижені, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 41 до 46 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бамбук, 5% Спандекс, 5% Лайкра 
        Виробництво ― Україна`
    },
    {
        id: "26",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks26_1.jpg", "/images/socks26_2.jpg", "/images/socks26_3.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM531) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки з антибактеріальним ефектом, гарна якість, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 41 до 46 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бамбук, 5% Спандекс, 5% Лайкра
        Виробництво ― Україна`
    },
    {
        id: "27",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks27_1.jpg", "/images/socks27_2.jpg", "/images/socks27_3.jpg", "/images/socks27_4.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM533) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки доброї якості, різні забарвлення.`,
        char: `
        Колір ― На фото (Можливо зміни кольорів в упаковці)
        Розмір ― Універсальний (тягнуться від 41 до 46 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 100% Бамбук 
        Виробництво ― Україна`
    },
    {
        id: "28",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks28_1.jpg", "/images/socks28_2.jpg", "/images/socks28_3.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM535)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки ""SPORT"" хорошої якості, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 100% Бамбук 
        Виробництво ― Україна`
    },
    {
        id: "29",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks29_1.jpg", "/images/socks29_2.jpg", "/images/socks29_3.jpg", "/images/socks29_4.jpg"],
        code: 'Шкарпетки чоловічі "Sport" МАХРА БАМБУК (Арт. OAM539) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки "Sport", хороша якість, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір — Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 100% Бамбук 
        Виробництво - Україна`
    },
    {
        id: "30",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks30_1.jpg", "/images/socks30_2.jpg", "/images/socks30_3.jpg"],
        code: 'Шкарпетки чоловічі "Sport" МАХРА БАМБУК (Арт. OAM538) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки "Sport" з антибактеріальним ефектом, гарна якість, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір — Універсальний (тягнуться від 40 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 100% Бамбук 
        Виробництво - Україна`
    },
    {
        id: "31",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks31_1.jpg", "/images/socks31_2.jpg", "/images/socks31_3.jpg"],
        code: 'Шкарпетки чоловічі МАХРА бавовна (Арт. OAM537) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, різні забарвлення.`,
        char: `
        Колір ― На фото 
        Розмір ― Універсальний (тягнуться від 41 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 90% Бавовна, 7% Поліамід, 3% Лайкра
        Виробництво ― Україна`
    },
    {
        id: "32",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks32_1.jpg", "/images/socks32_2.jpg", "/images/socks32_3.jpg", "/images/socks32_4.jpg"],
        code: 'Чоловічі махрові шкарпетки Житомир (OAM03027-29)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, різні забарвлення.`,
        char: `
        Колір - На фото
        Розмір - Універсальний 27-29 (42-44)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 85% Cotton, 15% Poliamide
        Виробник - Україна`
    },
    {
        id: "33",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks33_1.jpg", "/images/socks33_2.jpg", "/images/socks33_3.jpg", "/images/socks33_4.jpg", "/images/socks33_5.jpg", "/images/socks33_6.jpg"],
        code: 'Шкарпетки чоловічі "Житомир" МАХРА COTTON (Арт. OAM030/25-27) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, різні кольори.`,
        char: `
        Колір ― На фото (Можливо змінити кольори в упаковці)
        Розмір — Універсальний 25-27 (40-42)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 85% Cotton, 15% Poliamide
        Виробник -- Україна`
    },
    {
        id: "34",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks34_1.jpg", "/images/socks34_2.jpg", "/images/socks34_3.jpg", "/images/socks34_4.jpg"],
        code: 'Чоловічі махрові шкарпетки "Житомир" (OAM030/29-31) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, різні забарвлення.`,
        char: `
        Колір - На фото
        Розмір - Універсальний 29-31 (44-46)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 85% Cotton, 15% Poliamide
        Виробник - Україна`
    },
    {
        id: "35",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks35_1.jpg", "/images/socks35_2.jpg", "/images/socks35_3.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM036) | 12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки з антибактеріальним ефектом хорошого якості.`,
        char: `
        Колір - На фото
        Розмір - 40-45
        Кількість виробів в упаковці - 12 пар (одного розміру і кольору)
        Склад - 100% Bambu
        Виробник - Китай`
    },
    {
        id: "36",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks36_1.jpg", "/images/socks36_2.jpg", "/images/socks36_3.jpg", "/images/socks36_4.jpg", "/images/socks36_5.jpg"],
        code: 'Шкарпетки чоловічі МАХРА БАМБУК (Арт. OAM036MIX)  12 пар',
        price: 300,
        desc: `Чоловічі махрові шкарпетки з антибактеріальним ефектом доброї якості.`,
        char: `
        Колір ― На фото (Можливо змінити кольори в упаковці)
        Розмір - Універсальний (тягнуться від 40 до 45 розміру) )
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix забарвлень)
        Склад ― 100% Bambu
        Виробник - Китай`
    },
    {
        id: "37",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks37_1.jpg", "/images/socks37_2.jpg", "/images/socks37_3.jpg"],
        code: 'Шкарпетки чоловічі занижені Фенна бавовна МАХРА (Арт. FEA606)  12 пар',
        price: 300,
        desc: `Чоловічі махрові термо-шкарпетки торгової марки "Фенна", гарна якість, занижені, однотонні, різні забарвлення.`,
        char: `
        Колір - На фото
        Розмір - Універсальний (тягнуться від 41 до 47 розміру)
        Кількість виробів в упаковці - 12 пар (одного розміру, mix кольорів)
        Склад - 80% Бавовна, 15% Поліамід, 5% Спандекс
        Виробництво - Китай`
    },
    {
        id: "38",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks38_1.jpg", "/images/socks38_2.jpg", "/images/socks38_3.jpg"],
        code: 'Шкарпетки чоловічі з малюнком ЖИТОМИР МАХРА COTTON (Арт. AK340)',
        price: 300,
        desc: `Чоловічі махрові шкарпетки з новорічним принтом торгової марки "Житомир", добра якість, різні забарвлення та малюнки.`,
        char: `
        Колір ― На фото
        Розмір - Універсальний (тягнуться від 41 до 45 розміру)
        Кількість виробів в упаковці ― 12 пар (одного розміру, mix кольорів) )
        Склад ― 80% Cotton, 15% Polyester, 5% Elastane
        Виробництво ― Україна`
    },
    {
        id: "39",
        category: WarmSocks,
        subCategory: WarmSocksSubCategory,
        sizes: ["41-45"],
        urlImg: ["/images/socks39_1.jpg", "/images/socks39_2.jpg", "/images/socks39_3.jpg"],
        code: 'Шкарпетки чоловічі Житомир МАХРА ХЛОПОК (Арт. OAM544) 6 ПАР',
        price: 300,
        desc: `Чоловічі махрові шкарпетки торгової марки "Житомир", гарна якість, однотонні.`,
        char: `
        Колір ― На фото (хакі)
        Розмір ― Універсальний (тягнуться від 41 до 45 розміру)
        Кількість виробів в упаковці ― 6 пар (одного розміру та кольору)
        Склад ― 90% Бавовна, 7% Поліамід, 3% Лайкра
        Виробництво ― Україна`
    },
    {
        id: "40",
        category: WarmSocks,
        subCategory: Addittion,
        sizes: '',
        urlImg: ["/images/addition5_1.jpg", "/images/addition5_2.jpg", "/images/addition5_3.jpg"],
        code: 'Тепловентилятор Bobbytec PFH-103',
        price: 499,
        desc: `Тепловентилятор Bobbytec PFH-103 допоможе Вам під час відключення опалення, запізнілого запуску опалювального сезону, для опалення просто неопалюваних приміщень, на роботі, вдома, навіть у гаражі. Класичний тепловентилятор Bobbytec PFH-103 був створений спеціально, щоб дарувати тепло навколо.`,
        char: `
        - Потужність: 2000 Вт;
        - Площа приміщення, що рекомендується: 24 кв.м;
        - Кількість режимів роботи: 3;
        - Тип керування: Механічне;
        - Тип установки: Настільний, Підлоговий;
        - Регулювання швидкості;
        - Тип терморегулятора: Ступінчастий;
        - Індикатор роботи;
        - Вентиляція без нагрівання;
        - Захист від перегріву;
        - Матеріал: Якісний термостійкий пластик.`
    },]