import React, { useEffect, useState } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { CatalogData } from '../../data/catalog';
import Icon from '../../components/Common/Icon/icon';
import PrimaryButton from '../../components/Common/Button/primary-button';
import { useQuery } from '../../logic/hooks/useQuery';
import CartRow from '../../components/Common/CartComponent/components/cart-item';
import { CartData } from '../../data/cart-content';

const OrderPage = ({ cartData, setCartData }) => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [fatherName, setFatherName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [region, setRegion] = useState("")
    const [city, setCity] = useState("")
    const [section, setSection] = useState("")

    const onDeleteItem = (e, id) => {
        e.preventDefault()
        let newArray = [...cartData]
        let index = newArray.findIndex((x) => x.id == id)
        newArray.splice(index, 1);
        setCartData(newArray)
    }

    const SummarizePrice = () => {
        let sum = 0;
        cartData.forEach(x => {
            sum += x.price * x.count
        })

        return sum
    }

    const generateOrderList = () => {

        var list = ""
        cartData.forEach((x) => {

            list += `
  ${x.code}(${x.sizes}) Шт: ${x.count}
  `;
        })

        return list;
    }

    const sendMessageToBot = async (botToken, chatId, text) => {
        try {
            const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;
            const params = {
                chat_id: chatId,
                text: text
            };

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            });

            const responseData = await response.json();

            if (response.status === 200) {
                console.log('Message sent successfully.');
            } else {
                alert("Щось пішло не так. Можете спробувать пізніше або позвоніть за номером в шапці сайту")
                console.error(`Failed to send message. Error code: ${responseData.status}`);
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
        }
    }

    const onConfirmOrder = async () => {

        var message = `Нова заявка з сайта:

Замовлення:
${generateOrderList()}

Сума замовлення: ${SummarizePrice()} грн

Інформація доставки
    Ім'я: ${firstName} ${lastName} ${fatherName}
    Телефон: ${phoneNumber};
    Область: ${region};
    Населений пункт: ${city}
    Віділення: ${section}`;

        var token = "6508396749:AAGSkIjTxL-ZaoCQ04JCPbYd9h6fSZMzwKE";
        var chat_id = "-4016903344";
 
        await sendMessageToBot(token, chat_id, message)
    }

    const renderItems = cartData.map(x => {
        debugger
        return <CartRow id={x.id} code={x.code} urlImg={x.urlImg} name={x.name} size={x.sizes} count={x.count} price={x.price} onDeleteItem={onDeleteItem} />
    })


    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="order-container">
                    <div className='navigation' onClick={() => navigate(-1)}>← Повернутись до каталогу</div>
                    <div className="order-page-wrapper">
                        <h3 className='title'>Оформлення замовлення</h3>
                        <div className="order">
                            {renderItems}
                            <div className='total-price'>Всього до сплати: <span className='bold-text'>{SummarizePrice()} ₴</span></div>
                        </div>
                        <div className="contact-info">
                            <h4>Контактна інформація</h4>
                            <div className="contact-info-row">
                                <div className="contact-column">
                                    <input name='firstName' type='text' placeholder="Ім'я" onChange={(e)=>setFirstName(e.target.value)}></input>
                                    <input name='lastName' type='text' placeholder="Призвіще" onChange={(e)=>setLastName(e.target.value)}></input>
                                    <input name='firstName' type='phone' placeholder="+38 (095) 00 00 000" onChange={(e)=>setPhoneNumber(e.target.value)}></input>
                                </div>
                                <div className="contact-column">
                                    <input name='firstName' type='text' placeholder="Область" onChange={(e)=>setRegion(e.target.value)}></input>
                                    <input name='firstName' type='text' placeholder="Населений пункт" onChange={(e)=>setCity(e.target.value)}></input>
                                    <input name='firstName' type='text' placeholder="Віділення" onChange={(e)=>setSection(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                        <p className="paragraph-info">Після підтвердження заявки найближчим часом з вами зв'яжиться менеджер для обговорення деталей.</p>
                        <div className="order-button-group" onClick={onConfirmOrder}>
                            <Link to={'/thanks'}><PrimaryButton width={250} height={50} text={"Підтвердити замовлення"}></PrimaryButton></Link>
                        </div>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default OrderPage;