import React from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';
import IconButton from '../../components/Common/IconButton/button';

const ContactsPage = () => {
    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="contacts-container">
                    <h2>КОНТАКТИ</h2>
                    <p>ІНТЕРНЕТ-МАГАЗИН</p>
                    <div className="info-wrapper">
                        <div className="info-item">
                            <div className="info-item-icon">
                                <IconButton iconUrl={'icons/phone-black.svg'} width={25} height={25} />
                            </div>
                            <div className="info-item-col">
                                <div className="info-item-col-title">
                                    Телефон:
                                </div>
                                <div className="info-item-col-value">
                                    +38 (066) 510 90 85
                                </div>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="info-item-icon">
                                <IconButton iconUrl={'icons/email-black.svg'} width={25} height={25} />
                            </div>
                            <div className="info-item-col">
                                <div className="info-item-col-title">
                                    Почта:
                                </div>
                                <div className="info-item-col-value">
                                    diatools@email.ua
                                </div>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="info-item-icon">
                                <IconButton iconUrl={'icons/time-black.svg'} width={25} height={25} />
                            </div>
                            <div className="info-item-col">
                                <div className="info-item-col-title">
                                    Робочі години:
                                </div>
                                <div className="info-item-col-value">
                                    Пн - Пт: 8:00 - 19:00<br />
                                    Сб: 8:00 - 18:00
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default ContactsPage;