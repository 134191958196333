import React, { useEffect, useState } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';
import FilterBlock from './components/FilterBlock/filter-block';
import ProductContainer from './components/ProductList/product-list';
import { useQuery } from '../../logic/hooks/useQuery';
import { CatalogData } from '../../data/catalog';
import IconButton from '../../components/Common/IconButton/button';

const CatalogPage = ({ cartData, setCartData }) => {
    const [visibleFilterBlock, setVisibleFilterBlock] = useState(false);

    const query = useQuery();
    const category = query.get('category')
    const subCategory = query.get('subCategory')
    const search = query.get('search')

    let productFromData = []

    if (search) {
        productFromData = CatalogData.filter(x => x.code.toLowerCase().includes(search.toLowerCase()))
    } else {
        productFromData = CatalogData.filter(x => x.category == category)
        productFromData = subCategory == null ? productFromData : productFromData.filter(x => x.subCategory == subCategory)
    }


    const [products, setSelectedProducts] = useState(productFromData);
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(999999)

    const onChangeMinPrice = (minPrice) => {
        setMinPrice(minPrice)
    }

    const onChangeMaxPrice = (maxPrice) => {
        setMaxPrice(maxPrice)
    }

    const filterPrice = () => {
        setSelectedProducts(productFromData.filter(x => x.price >= minPrice && x.price <= maxPrice))
    }


    useEffect(() => {
        filterPrice()
    }, [minPrice, maxPrice])


    useEffect(() => {
        setSelectedProducts(productFromData)
    }, [category, subCategory, search])


    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="catalog-page-container">
                    <div className="filterIcon" onClick={() => setVisibleFilterBlock(true)}>
                        <IconButton iconUrl={"icons/filter.svg"} width={'40px'} height={'40px'} />
                    </div>
                    <FilterBlock visible={visibleFilterBlock} setVisible={setVisibleFilterBlock} onChangeMinPrice={onChangeMinPrice} onChangeMaxPrice={onChangeMaxPrice}></FilterBlock>
                    <ProductContainer cartData={cartData} setCartData={setCartData} products={products}></ProductContainer>
                </div>
            </CommonLayout>
        </>
    )
}

export default CatalogPage;