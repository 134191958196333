import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const ProductCart = ({ id, urlImg, price, code, stone, addProductToCart }) => {
    const [imageCount, setImageCount] = useState(0)

    const AddToCart = (e) => {
        e.preventDefault();
        addProductToCart({id, urlImg, price, code, stone, count: 1})
    }

    return (
        <>
            <Link to={id}>
                <div className="product-cart">
                    <div className="product-image">
                    <img src={typeof (urlImg) == typeof ([]) ? urlImg[imageCount] : urlImg} />
                    </div>
                    <div className="product-price">{price} <br />грн/комплект</div>
                    <div className="product-desc">
                        <div className="row">
                            <div className="row-value">{code}</div>
                        </div>
                    </div>
                    <div className="buttons">
                        {/* <div className="in-cart">
                        <IconButton iconUrl={'icons/black-cart.svg'} width={'30px'} height={'30px'}></IconButton>
                    </div> */}
                        <button className="primary-button order-by" onClick={AddToCart}>Додати в кошик</button>
                    </div>
                </div>
            </Link>

        </>
    )
}

export default ProductCart;