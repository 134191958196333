import React from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import Blocks from '../../components/Common/Blocks/blocks';
import { CatalogData } from '../../data/catalog';
import SaleProductCart from '../CatalogPage/components/SaleProductCart/product-cart';
import AdditionProductCart from '../CatalogPage/components/AdditionProductCart/product-cart';
import { Addittion } from '../../logic/const/constants';

const MainPage = ({ cartData, setCartData }) => {

    const AddAdditionProductToCart = (object) => {
        debugger
        let index = cartData.findIndex(x => x.id == object.id);

        if (index != -1) {
            var newCart = [...cartData];
            newCart[index].count += 1
            setCartData(newCart)
        } else {
            setCartData(cartData => [...cartData, { ...object, count: 1 }]);
        }
        console.log(cartData)
    }

    const salesProduct = CatalogData.filter(x => x.id == "12" || x.id == "5" || x.id == "3" || x.id == "30" || x.id == "25").map(x => {
        return <><SaleProductCart id={x.id} urlImg={x.urlImg} price={x.price} code={x.code} sizes={x.sizes} stone={x.stone} /></>
    })

    const salesAdditionProduct = CatalogData.filter(x => x.subCategory == Addittion).map(x => {
        return <><AdditionProductCart id={x.id} urlImg={x.urlImg} price={x.price} code={x.code} sizes={x.sizes} stone={x.stone} addProductToCart={AddAdditionProductToCart} /></>
    })

    return (
        <>
            <CommonLayout backgroundColor="white">
                <div className="top-container">
                    <div className="background">
                        <img src="images/main.jpg" alt='main'/>
                    </div>
                    <div className="wrapper">
                        <div className="text">
                            <h1>
                                Якісні шкарпетки від виробника
                            </h1>
                            <div className="sub-text">
                                Найкращі шкарпетки за вигідними цінами.Для виготовлення шкарпетків використовуються натуральні матеріали
                            </div>
                        </div>
                        <div className="angel">
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="sale-block">
                        <h4>Топ продажів:</h4>
                        <div className="sale-block-wrapper">
                            {salesProduct}
                        </div>
                    </div>
                    <div className="addition-block">
                        <h4>Додаткові товари:</h4>
                        <div className="addition-block-wrapper">
                            {salesAdditionProduct}
                        </div>
                    </div>
                </div>

            </CommonLayout>
        </>
    )
}

export default MainPage;