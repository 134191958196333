import IconButton from '../../IconButton/button';
import './style.scss';

const CartRow = ({ id, urlImg, code, size, count, price, onDeleteItem }) => {
    return (
        <>
            <div className="cart-row">
                <div className="product-image">
                    <img src={typeof (urlImg) == typeof ([]) ? urlImg[0] : urlImg}></img>
                </div>
                <div class='product-code'>{code} {size != undefined ? `(${size})` : ''}</div>
                <div>Шт: {count}</div>
                <div className="price-column">
                    <div className="single-row-price">Ціна за одиницю: <span className='bold-text'>{price} ₴</span></div>
                    <div className="total-row-price">Всього: <span className='bold-text'>{price * count} ₴</span></div>
                </div>
                <div onClick={(e) => onDeleteItem(e, id)}>
                    <IconButton width={'36px'} height={'36px'} iconUrl={'/icons/delete-icon.svg'} />
                </div>
            </div>
        </>

    )
}

export default CartRow;