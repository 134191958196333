import React, { useEffect, useState } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { CatalogData } from '../../data/catalog';
import Icon from '../../components/Common/Icon/icon';
import PrimaryButton from '../../components/Common/Button/primary-button';
import { useQuery } from '../../logic/hooks/useQuery';
import { CartData } from '../../data/cart-content';
import { Addittion } from '../../logic/const/constants';
import ProductCart from '../CatalogPage/components/ProductCart/product-cart';
import SaleProductCart from '../CatalogPage/components/SaleProductCart/product-cart';
import AdditionProductCart from '../CatalogPage/components/AdditionProductCart/product-cart';

const DetailProduct = ({ cartData, setCartData }) => {
    const { id } = useParams();
    const query = useQuery();
    const navigate = useNavigate();
    const [product, setProduct] = useState(CatalogData.find(x => x.id == id))
    const [imageCount, setImageCount] = useState(0)
    const [textField, setTextField] = useState(0);
    const [selectedSize, setSelectedSize] = useState(0);


    const AddProductToCart = () => {
        let index = cartData.findIndex(x => x.id == id);

        if (index != -1) {
            debugger
            if (cartData[index].sizes == product.sizes[selectedSize]) {
                let newCart = [...cartData]
                newCart[index].count += 1;
                setCartData(newCart);
            } else {
                setCartData(cartData => [...cartData, { ...product, sizes: product.sizes[selectedSize], count: 1 }]);
            }
        } else {
            setCartData(cartData => [...cartData, { ...product, sizes: product.sizes[selectedSize], count: 1 }]);
        }
    }

    const AddAdditionProductToCart = (object) => {
        debugger
        let index = cartData.findIndex(x => x.id == object.id);

        if (index != -1) {
            var newCart = [...cartData];
            newCart[index].count += 1
            setCartData(newCart)
        } else {
            setCartData(cartData => [...cartData, { ...object, count: 1 }]);
        }
        console.log(cartData)
    }

    const OrderNow = () => {
        let index = cartData.findIndex(x => x.id == product.id);
        debugger
        if (index != -1) {
            if (cartData[index].sizes == product.sizes[selectedSize]) {
                let newCart = [...cartData]
                newCart[index].count += 1;
                setCartData(newCart);
            } else {
                setCartData(cartData => [...cartData, { ...product, sizes: product.sizes[selectedSize], count: 1 }]);
            }
        } else {
            setCartData(cartData => [...cartData, { ...product, sizes: product.sizes[selectedSize], count: 1 }]);
        }
        
        navigate('/order-confirm');
    }

    const nextImage = () => {
        if (product.urlImg.length - 1 > imageCount) {
            setImageCount(imageCount + 1)
        } else {
            setImageCount(0)
        }
    }

    const previousImage = () => {
        if (imageCount != 0) {
            setImageCount(imageCount - 1)
        }
    }

    const renderSizes = () => {
        return product.sizes && product.sizes.map(x => {
            return <div class={`sizes-point ${selectedSize == product.sizes.indexOf(x) ? 'active' : ''}`} onClick={() => setSelectedSize(product.sizes.indexOf(x))}>{x}</div>
        }) 
    }

    const salesProduct = CatalogData.filter(x => x.id == "12" || x.id == "5" || x.id == "3").map(x => {
        return <><SaleProductCart id={x.id} urlImg={x.urlImg} price={x.price} code={x.code} sizes={x.sizes} stone={x.stone} addProductToCart={AddProductToCart} /></>
    })

    const salesAdditionProduct = CatalogData.filter(x => x.subCategory == Addittion).map(x => {
        return <><AdditionProductCart id={x.id} urlImg={x.urlImg} price={x.price} code={x.code} sizes={x.sizes} stone={x.stone} addProductToCart={AddAdditionProductToCart} /></>
    })

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });        
    }, [id])


    return (
        <>
            <CommonLayout backgroundColor={'white'}>
                <div className="detail-product-container">
                    <div className='navigation' onClick={() => navigate(-1)}>← Повернутись до каталогу</div>
                    <div className="product-block">
                        <div className="product-image">
                            <div className="product-image-wrapper">
                                <div className="arrow-wrapper" onClick={previousImage} style={{ display: typeof (product.urlImg) == typeof ([]) ? "" : "none" }}>
                                    <Icon iconUrl={"/icons/arrow-left.svg"} width={"40px"} height={"40px"}></Icon>
                                </div>
                                <img src={typeof (product.urlImg) == typeof ([]) ? product.urlImg[imageCount] : product.urlImg}></img>
                                <div className="arrow-wrapper right" onClick={nextImage} style={{ display: typeof (product.urlImg) == typeof ([]) ? "" : "none" }}>
                                    <Icon iconUrl={"/icons/arrow-left.svg"} width={"40px"} height={"40px"}></Icon>
                                </div>
                            </div>
                            <div className="contact-phone-block">
                                <h4>+380965477575</h4>
                                <div className="sub-text-contact">Менеджер замовлень</div>
                            </div>
                        </div>
                        <div className="product-detail">
                            <p>Артикул: {product.code}</p>
                            <div className="price">Ціна: {product.price} грн/комплект</div>
                            <div className="instock">В наявності</div>
                            <div className="desc">
                                <div className="desc-value-text">
                                    <div className="choose-text-field">
                                        <div className={`choose-text-value ${textField == 0 ? 'active' : ''}`} onClick={() => setTextField(0)}>Опис</div>
                                        <div className={`choose-text-value ${textField == 1 ? 'active' : ''}`} onClick={() => setTextField(1)}>Характеристики</div>
                                    </div>
                                    <div className='text-value'>{textField == 0 ? product.desc : product.char}</div>

                                </div>
                            </div>
                            {product.sizes != null ? <div className="sizes">
                                Розміри в наявності:
                                <div className="sizes-wrapper">{renderSizes()}</div>
                            </div> : null}

                            <div className="buttons-group">
                                <div onClick={AddProductToCart}>
                                    <PrimaryButton width={"300px"} height={"50px"} text={'Додати в кошик'}></PrimaryButton>
                                </div>
                                <div class="order-now" onClick={OrderNow}>
                                    <PrimaryButton width={"300px"} height={"50px"} text={'Придбати зараз'}></PrimaryButton>
                                </div>
                            </div>
                            <div className="line"></div>
                            <div className="conditions">
                                <div className="temp">
                                    <Icon iconUrl={"/icons/delivery.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Доставка в межах України здійснюється собі вартістю транспорту або поштовими службами (Нова Пошта, УкрПошта, ІнТайм та інші). Наші менеджери підберуть найбільш оптимальний спосіб доставки індивідуально.
                                    </p>
                                </div>
                                <div className="temp">
                                    <Icon iconUrl={"/icons/money.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Ми приймаємо різні види платежів: готівка, наложений платіж, переказ на банківський рахунок, оплата картками Visa/MasterCard                                </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="addition-block">
                        <h4>Додаткові товари:</h4>
                        <div className="addition-block-wrapper">
                            {salesAdditionProduct}
                        </div>
                    </div>
                    <div className="sale-block">
                        <h4>Товари які можуть вам сподобатися:</h4>
                        <div className="sale-block-wrapper">
                            {salesProduct}
                        </div>
                    </div>
                </div>
            </CommonLayout>
        </>
    )
}

export default DetailProduct;