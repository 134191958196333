//Category
export const WarmSocks = "WarmSocks";

//SubCategory Monuments
export const TermoSocks = "termo-socks";
export const WarmSocksSubCategory = "warm-socks";
export const Military = "military";
export const Optom = "optom";
export const Addittion = "addition";

export const WarmSocksForFilterBlock = [
    [WarmSocksSubCategory, "Теплі шкарпетки"],
    [TermoSocks, "Термо шкарпетки"],    
    [Military, "Військові шкарпетки"],
    [Optom, "Оптом"],
    [Addittion, "Додаткові товари"],
];
