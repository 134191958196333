import { useState } from 'react';
import React from 'react';
import Catalog from '../Common/CatalogComponent/catalog';
import IconButton from '../Common/IconButton/button';
import './style.scss';
import {
    Link, redirect, useNavigate, useSearchParams
} from "react-router-dom";
import Cart from '../Common/CartComponent/cart';
import { useEffect } from 'react';
import { Addittion, Military, Optom, TermoSocks, WarmSocks, WarmSocksSubCategory } from '../../logic/const/constants';

const Header = ({ cartData, setCartData }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [cartCount, setCartCount] = useState(cartData.length);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };

    const onChangeSearch = (event) => {
        const value = event?.target.value;
        if (value == '') {
            navigate(`/catalog`)
        } else {
            navigate(`/catalog?search=${value}`)
        }
    }

    useEffect(() => {
        setCartCount(cartData.length)
    }, [cartData])

    return (
        <>
            <header>
                <div className="header-wrapper">
                    <div className="left">
                        <Link to="/">
                            <div className="logo">
                                KRST SOCKS
                                <div className="sub-text">Найкращі шкарпетки</div>
                            </div>
                        </Link>
                    </div>
                    <div className="center">
                        <div className="menu">
                            <div onClick={toggleMenu}>
                                <IconButton width={'30px'} height={'30px'} iconUrl={'/icons/menu.svg'} />
                            </div>
                            <Catalog isOpen={isMenuOpen} onClose={toggleMenu} />
                        </div>
                        <div className="search">
                            <input type='text' onChange={onChangeSearch}></input>
                            <div className="icon">
                                <img src='/icons/search.svg' />
                            </div>
                        </div>
                        <div className="cart-pc">
                            <div class="cart-icon-wrapper" onClick={toggleCart}>
                                <div className="cart-count">{cartCount}</div>
                                <IconButton width={'36px'} height={'36px'} iconUrl={'/icons/cart-icon.svg'} />
                            </div>
                            <Cart isOpen={isCartOpen} onClose={toggleCart} cartData={cartData} setCartData={setCartData} />
                        </div>
                        <div className="cart-mobile">
                            <Link class="cart-icon-wrapper" to={'/order-confirm'}>
                                <div className="cart-count">{cartCount}</div>
                                <IconButton width={'36px'} height={'36px'} iconUrl={'/icons/cart-icon.svg'} />
                            </Link>
                        </div>
                    </div>
                    <div className="right">
                        <div className="contacts">
                            <IconButton width={'24px'} height={'24px'} iconUrl={'/icons/viber-icon.svg'} />
                            <IconButton width={'24px'} height={'24px'} iconUrl={'/icons/telegram-icon.svg'} />
                            <div className="phone-number">
                                +38 (096) 547 7575
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-category">
                    <ul class="header-category-list">
                        <li><Link to={`catalog?category=${WarmSocks}`}>Усі</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${WarmSocksSubCategory}`}>Теплі шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${TermoSocks}`}>Термо шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Military}`}>Військові шкарпетки</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Optom}`}>Оптом</Link></li>
                        <li><Link to={`catalog?category=${WarmSocks}&subCategory=${Addittion}`}>Додаткові товари</Link></li>
                        <li><Link to={'contacts'}>Контакти</Link></li>
                        <li><Link to={'delivery'}>Доставка і оплата</Link></li>
                    </ul>
                </div>
            </header>
        </>
    )
}

export default Header;